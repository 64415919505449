var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "ignore-button", attrs: { offset: 17, span: 7 } },
            [
              _c(
                "el-button-group",
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "ignore-button",
                      attrs: {
                        effect: "dark",
                        content: "Xuất excel về máy",
                        placement: "top-start",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { type: "success", icon: "el-icon-download" },
                        on: { click: _vm.handleDownloadExcel },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "ignore-button",
                      attrs: {
                        effect: "dark",
                        content: "In pdf",
                        placement: "top-start",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { type: "success", icon: "el-icon-files" },
                        on: { click: _vm.handlePrint },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "Cài đặt",
                        placement: "top-start",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { type: "success", icon: "el-icon-setting" },
                        on: { click: _vm.settingFontSize },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.dataBillPreview, function (data, index) {
        return _c(
          "div",
          { key: index, staticClass: "pdf_container", style: _vm.customStyle },
          [
            _c("br"),
            _c(
              "div",
              { staticStyle: { width: "96%", margin: "auto" } },
              [
                data.logoSchool !== null
                  ? _c(
                      "el-row",
                      { staticClass: "header_kids" },
                      [
                        _c("el-col", { attrs: { span: 6 } }, [
                          _c("img", {
                            attrs: {
                              id: "logo_onecam",
                              src: data.logoSchool,
                              alt: "",
                            },
                          }),
                        ]),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "line-height": "1.5",
                            },
                            attrs: { span: 12 },
                          },
                          [
                            _c("p", [
                              _c("b", {}, [_vm._v(_vm._s(data.nameSchool))]),
                              _c("br"),
                              _c("span", [
                                _vm._v(_vm._s(data.addressSchool) + " "),
                              ]),
                              _c("br"),
                              _c("b", [
                                _vm._v(
                                  "PHIẾU THU HỌC PHÍ THÁNG " +
                                    _vm._s(_vm.dataSearch.month) +
                                    "/" +
                                    _vm._s(_vm.dataSearch.createdYear) +
                                    " - ĐỢT " +
                                    _vm._s(_vm.dataSearch.collectionTurn)
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "el-row",
                      { staticClass: "header_kids" },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "line-height": "1.5",
                            },
                            attrs: { offset: 6, span: 12 },
                          },
                          [
                            _c("p", [
                              _c("b", {}, [_vm._v(_vm._s(data.nameSchool))]),
                              _c("br"),
                              _c("span", [
                                _vm._v(_vm._s(data.addressSchool) + " "),
                              ]),
                              _c("br"),
                              _c("b", [
                                _vm._v(
                                  "PHIẾU THU HỌC PHÍ THÁNG " +
                                    _vm._s(_vm.dataSearch.month) +
                                    "/" +
                                    _vm._s(_vm.dataSearch.createdYear) +
                                    " - ĐỢT " +
                                    _vm._s(_vm.dataSearch.collectionTurn)
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { staticClass: "print__left", attrs: { span: 12 } },
                      [
                        _c("span", [
                          _c("b", [_vm._v("Họ tên:")]),
                          _vm._v(
                            " " +
                              _vm._s(data.nameKid) +
                              " (" +
                              _vm._s(data.kidCode) +
                              ")"
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "print__right", attrs: { span: 12 } },
                      [
                        _c("span", [
                          _c("b", [_vm._v("Mã:")]),
                          _vm._v(" .........."),
                        ]),
                      ]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "print__left", attrs: { span: 12 } },
                      [
                        _c("span", [
                          _c("b", [_vm._v("Lớp:")]),
                          _vm._v(" " + _vm._s(data.nameClass)),
                        ]),
                      ]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "print__right", attrs: { span: 12 } },
                      [
                        _c("span", [
                          _c("b", [_vm._v("Số:")]),
                          _vm._v(" .........."),
                        ]),
                      ]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "print__left", attrs: { span: 12 } },
                      [
                        _c("span", [
                          _c("b", [_vm._v("SĐT:")]),
                          _vm._v(" " + _vm._s(data.phoneKid)),
                        ]),
                      ]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "print__right", attrs: { span: 12 } },
                      [
                        _c("span", [
                          _c("b", [_vm._v("Quyển số:")]),
                          _vm._v(" .........."),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticStyle: { "margin-top": "6px" } },
                  [
                    _vm.configData.statusPrint !== true
                      ? _c("print-null-table", {
                          attrs: { "table-data": data },
                        })
                      : _c("print-not-null-table", {
                          attrs: { "table-data": data },
                        }),
                  ],
                  1
                ),
                _c("el-row", { staticStyle: { "margin-top": "5px" } }, [
                  _c("span", [
                    _c("b", [_vm._v("Ghi chú hóa đơn:")]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "white-space": "pre-line",
                          "font-style": "italic",
                        },
                      },
                      [_vm._v(_vm._s(data.noteExcel2))]
                    ),
                  ]),
                  _c("i", [_vm._v(" " + _vm._s(data.expired))]),
                ]),
                _c(
                  "el-row",
                  { staticStyle: { "margin-top": "5px" } },
                  [
                    _c(
                      "el-col",
                      { staticClass: "print__left", attrs: { span: "8" } },
                      [
                        _c("span", [
                          _c("b", [_vm._v("STK:")]),
                          _vm._v(" " + _vm._s(data.accountNumber)),
                        ]),
                      ]
                    ),
                    _c("el-col", { attrs: { span: "8" } }, [
                      _c("span", [
                        _c("b", [_vm._v("Chủ TK:")]),
                        _vm._v(" " + _vm._s(data.fullName)),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("el-row", [
                  _c("span", [
                    _c("b", [_vm._v("Ngân hàng:")]),
                    _vm._v(" " + _vm._s(data.bankName)),
                  ]),
                ]),
                _c(
                  "el-row",
                  { staticClass: "footer_field" },
                  [
                    _vm.configData.statusShowDateTime
                      ? _c("el-col", { attrs: { offset: 6 } }, [
                          _c("span", [
                            _c("i", [
                              _vm._v(
                                _vm._s(_vm.currentTime) +
                                  ", ngày " +
                                  _vm._s(_vm.day) +
                                  " tháng " +
                                  _vm._s(_vm.month) +
                                  " năm " +
                                  _vm._s(_vm.year)
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("b", [_vm._v("Người nộp tiền")]),
                    ]),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("b", [_vm._v("Người lập phiếu")]),
                    ]),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-top": "70px" },
                        attrs: { offset: 12, span: 12 },
                      },
                      [_c("b", [_vm._v(_vm._s(data.userName))])]
                    ),
                  ],
                  1
                ),
                _c("div", {
                  class: {
                    "page-break": true,
                    "last-page-break": index === _vm.dataBillPreview.length - 1,
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "Thiết lập cỡ chữ",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                width: "300px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "8px" },
                      attrs: { span: 8 },
                    },
                    [_c("span", [_vm._v("Chọn cỡ chữ")])]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "70px" },
                          attrs: { placeholder: "Chọn cỡ chữ", size: "smail" },
                          model: {
                            value: _vm.customFontSize,
                            callback: function ($$v) {
                              _vm.customFontSize = $$v
                            },
                            expression: "customFontSize",
                          },
                        },
                        _vm._l(_vm.listFontSize, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("Đóng")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }